<template>
  <div class="tickets-addition">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="20vh"
               class="tickets-addition-dialog">
      <div slot="title"
           class="tickets-addition-form__header">
        <span>新增票券</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="tickets-addition-form__body">
          <el-form-item label="票券名称"
                        prop="name">
            <el-input v-model="form.name"
                      placeholder="请输入票券名称"
                      maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="选择记账单位"
                        prop="type">
            <el-select v-model="form.type"
                       placeholder="请选择记账单位"
                       filterable
                       :loading="loading"
                       clearable
                       @change="optionChange">
              <el-option v-for="item in options"
                         :value="item.value"
                         :label="item.label"
                         :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效期"
                        prop="expiry">
            <el-input v-model="unit.expiry"
                      disabled></el-input>
          </el-form-item>
          <el-form-item label="包含数量"
                        prop="contain">
            <el-input v-model="form.contain"
                      placeholder="请输入含有记账单位数量"
                      maxlength="4"
                      clearable
                      @input="limitPositiveInteger('contain')"></el-input>
          </el-form-item>
          <el-form-item label="票券总数"
                        prop="amount">
            <el-input v-model="form.amount"
                      placeholder="请输入票券总数"
                      maxlength="10"
                      clearable
                      @input="limitPositiveInteger('amount')"></el-input>
         <div class="tickets-addition-form__total">
          <span class="info">合计：<span class="balance"
                  v-if="form.contain && form.amount">{{form.contain * form.amount}}个记账单位</span></span>
          <span class="info">当前余额：<span>{{unit.balance && unit.balance - form.amount * form.contain}}</span></span>
        </div>
          </el-form-item>
          <el-form-item label="票券类型"
                        prop="ticketType"
                       >
            <el-select v-model="form.ticketType" placeholder="请选择票券类型">
              <el-option :label="t.name" :value="t.id" v-for="(t, index) in ticketTypeList" :key="index"></el-option>
            </el-select>
             <div class="upload-tips">{{tags}}</div>
          </el-form-item>
          <el-form-item label="详情图片" prop="detailPictures" v-if="tags.indexOf('基础详情')>-1">
            <el-upload  ref="upload"
              :action="uploadUrl"
              list-type="picture-card"
              :headers="headers"
              :before-upload="beforUpload"
              :on-success="successUpload"
              name="file"
              accept="image/*"
              :on-remove="handleRemove"
              :multiple="true">
              <i class="el-icon-plus"></i>
            </el-upload>
            <div class="upload-tips">* 比例为1:1的图片，支持png和jpg，不超过10M</div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="tickets-addition-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('form')">确定</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
import timeFormat from '@/utils/timeFormat.js'
import { getToken } from '@/utils/localStorage'

export default {
  data() {
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入票券名称'))
      } else {
        return callback()
      }
    }

    let validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择记账单位'))
      } else {
        return callback()
      }
    }
    // 校验记账单位数量
    let validateAmount = (rule, value, callback) => {
      if (this.unit.balance === 0) {
        return callback(new Error('当前记账单位余额为0，无法输入票券数'))
      } else if (!value) {
        return callback(new Error('请输入票券总数'))
      } else {
        return callback()
      }
    }
    // 校验记账单位数量
    let validateContain = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入含有记账单位数量'))
      } else {
        return callback()
      }
    }
    return {
      // 新建的票券相关信息
      form: {
        name: '',
        type: '', // 使用的记账单位
        contain: '', // 包含的记账单位数量
        amount: '',
        ticketType: '',
        detailPictures: []
      },
      // 记账单位相关信息
      unit: {
        id: '',
        balance: '', // 选择的记账单位的余额
        expiry: ''
      },
      options: [],
      // 校验规则
      rules: {
        name: [
          { validator: validateName, trigger: ['blur', 'change'] }
        ],
        type: [
          { validator: validateType, trigger: 'change' }
        ],
        amount: [
          { validator: validateAmount, trigger: ['blur', 'change'] }
        ],
        contain: [
          { validator: validateContain, trigger: ['blur', 'change'] }
        ],
        ticketType: [
          { validator: (rule, val, callback) => {
            if (val) {
              callback()
            } else {
              callback(new Error('请选择票券类型'))
            }
          },
          trigger: ['blur', 'change'] }
        ],
        detailPictures: [
          { validator: (rule, val, callback) => {
            if (this.form.detailPictures.length > 0) {
              callback()
            } else {
              callback(new Error('请至少上传一张详情图片'))
            }
          }
          }
        ]
      },
      dialogWidth: '0',
      visible: false,
      loading: false,
      ticketTypeList: [],
      tags: ''
    }
  },
  computed: {
    uploadUrl() {
      return window.BASE_URL + '/upload'
    },
    headers() {
      return { sessionId: getToken() }
    }
  },
  mounted() {
    this.clearForm()
    this.ticketTypeList = []
    this.visible = true
    this.setDialogWidth()
    this.getUnitOptions()
    this.getType()
    // 监听浏览器窗口大小变化，用于新增记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  // 移除事件监听
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    },
    'form.contain'() {
      this.checkAmount()
    },
    'form.amount'() {
      this.checkAmount()
    },
    'form.ticketType' (newVal, old) {
      console.log(newVal)
      this.ticketTypeList.some(item => {
        if (item.id === newVal) {
          this.tags = item.tags.join('+')
        }
      })
    }
  },
  methods: {
    // 清空窗口的输入内容
    clearForm() {
      this.form.name = ''
      this.form.type = ''
      this.form.contain = ''
      this.form.amount = ''
      this.form.ticketType = ''
      this.form.detailPictures = []
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ticket = deepClone(this.form)
          this.$emit('create', ticket)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '500px'
      }
    },
    // 控制记账单位只能输入正整数
    limitPositiveInteger(value) {
      if (this.form[value].length === 1) {
        this.form[value] = this.form[value].replace(/[^1-9]/g, '')
      } else {
        this.form[value] = this.form[value].replace(/\D/g, '')
      }
    },
    getType() {
      this.$http.ticket.getCurrentUserTicketType().then(res => {
        if (res.data.code === 0) {
          let ticketList = res.data.data
          this.handleTicketTypeList(ticketList)
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 获取记账单位列表
    getUnitOptions() {
      this.$http.unit.getUserUnitList('available').then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.records = res.data.data
          this.options = []
          if (res.data.data) {
            for (let r of this.records) {
              this.options.push({ value: r.accountingId, label: r.name })
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 远程搜索记账单位
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true
    //     // 获取后端返回记账单位记录
    //     this.getUnitOptions(query)
    //   }
    // },
    optionChange() {
      // 获取选择的记账单位的信息(名称，id，有效期)
      if (this.form.type) {
        let res = this.records.filter(item => item.accountingId === this.form.type)
        this.unit.id = this.form.type
        this.unit.balance = res[0].remainderQuantity
        this.unit.expiry = timeFormat(res[0].beginTime, 'YYYY.MM.DD') + ' - ' + timeFormat(res[0].endTime, 'YYYY.MM.DD')
        this.checkAmount()
      } else {
        this.unit.id = ''
        this.unit.expiry = ''
        this.unit.balance = ''
        this.form.amount = ''
        this.form.contain = ''
      }
    },
    checkAmount() {
      if (this.form.contain > this.unit.balance) {
        this.form.contain = this.unit.balance
      } else {
        if (this.form.amount * this.form.contain > this.unit.balance) {
          this.form.amount = Math.floor(this.unit.balance / this.form.contain)
          if (this.form.amount === 0) {
            this.form.amount = ''
          }
        }
      }
    },
    handleTicketTypeList(list) {
      this.ticketTypeList = list
    },
    beforUpload (file) {
      if (file.size / 1024 / 1024 > 10) {
        this.$message({
          type: 'warning',
          message: '每张图片大小不能大于10M'
        })
        return false
      }
    },
    successUpload (response, file, fileList) {
      console.log(fileList)
      if (response.code === 0) {
        this.form.detailPictures = fileList.map(item => {
          return window.BASE_URL + item.response.data.path
        })
        console.log(this.form.detailPictures)
      }
    },
    handleRemove (file, fileList) {
      this.form.detailPictures = fileList.map(item => {
        return window.BASE_URL + item.response.data.path
      })
    }
  }
}
</script>

<style lang="scss">
.tickets-addition-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 24px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.tickets-addition {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 292px;
        }
        .el-date-editor {
          width: 260px;
          .el-range-separator {
            width: 12%;
            font-weight: 600;
            padding: 0;
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
    &__total {
      user-select: none;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-extra-small;
        margin-right: 40px;
        color: #b3b4ba;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 10px 20px;
    }
  }
}

@media screen and (max-width: 375px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 200px;
        }
        .el-date-editor {
          width: 200px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
}

@media screen and (max-width: 320px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 183px;
        }
        .el-date-editor {
          width: 183px;
        }
      }
    }
  }
}
.upload-tips{
   display: inline-block;
  font-size: $--font-size-extra-small;
  color: #b3b4ba;
}
</style>
